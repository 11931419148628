import React from "react"

import { WorkshopTile1, WorkshopTile2 } from "./workshopTiles.js"

const getDate = dateString => {
  const dateObject = new Date(dateString)
  const year = dateObject.getFullYear()
  const monthDay = dateObject.getDate()
  const monthKey = dateObject.getMonth()
  const weekDayKey = dateObject.getDay()
  // https://developer.mozilla.org/en-us/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleTimeString#using_options
  const time = dateObject.toLocaleTimeString("en-us", {
    timeZone: "America/Winnipeg",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  })
  const monthArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  const weekDayArray = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]
  const month = monthArray[monthKey]
  const weekDay = weekDayArray[weekDayKey]

  return {
    full: `${month} ${monthDay}, ${year}`,
    year,
    monthDay,
    month,
    weekDay,
    time,
  }
}

// function to render all of the workshops for a given day
const RenderWorkshops = ({ workshops }) => {
  let count = 0

  return (
    <div className="grid row-gap-8 mt-5 mb-10">
      {workshops.map(({ node: workshop }) => {
        const {
          title,
          summary,
          conferenceDate,
          startTime,
          endTime,
          slug: { current: slug },
          eventLink,
          isVirtualEvent,
        } = workshop

        // if conferenceDate was provided assign value to date, else set it date null
        const date = conferenceDate ? conferenceDate.date : null

        {
          /* count for setting tile color */
        }

        // const formattedStarttime = new Date(startTime)
        // const theYear = formattedStarttime.getFullYear()
        // const theMonth = formattedStarttime.getMonth()

        // Format date and create cool date object if startTime exists, otherwise it's null
        // Why is this better as an if/else not ternary.  I did that for some reason or other. More explicit?
        // I remember now - because in the same statement, I want to define the DATE
        const fullStartDate = startTime ? getDate(startTime) : null
        let workShopStartTime
        let workShopDate
        if (fullStartDate) {
          workShopStartTime = fullStartDate.time
          workShopDate = fullStartDate.full
        } else {
          workShopStartTime = null
          workShopDate = null
        }

        // Format date and create cool date object if endTime exists, otherwise it's null
        const fullEndDate = endTime ? getDate(endTime) : null
        let workShopEndTime
        if (endTime) {
          workShopEndTime = fullEndDate.time
        } else {
          workShopEndTime = null
        }

        count = count < 4 ? count + 1 : 1
        switch (count) {
          case 1:
            return (
              <WorkshopTile1
                bgcolor="bg-blue-700"
                title={title}
                description={summary}
                dateColor="text-blue-400"
                color="blue"
                date={workShopDate}
                startTime={workShopStartTime}
                endTime={workShopEndTime}
                slug={slug}
                eventLink={eventLink}
                isVirtualEvent={isVirtualEvent}
              />
            )
          case 2:
            return (
              <WorkshopTile2
                title={title}
                description={summary}
                color="green"
                date={workShopDate}
                startTime={workShopStartTime}
                endTime={workShopEndTime}
                slug={slug}
                eventLink={eventLink}
                isVirtualEvent={isVirtualEvent}
              />
            )
          case 3:
            return (
              <WorkshopTile1
                title={title}
                description={summary}
                color="red"
                date={workShopDate}
                slug={slug}
                endTime={workShopEndTime}
                startTime={workShopStartTime}
                eventLink={eventLink}
                isVirtualEvent={isVirtualEvent}
              />
            )
          case 4:
            return (
              <WorkshopTile2
                title={title}
                description={summary}
                color="black"
                date={workShopDate}
                slug={slug}
                endTime={workShopEndTime}
                startTime={workShopStartTime}
                eventLink={eventLink}
                isVirtualEvent={isVirtualEvent}
              />
            )
        }
      })}
    </div>
  )
}

export default RenderWorkshops
