import React, { useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import { ConferenceContext } from "../../context/conferenceContext"
import { ButtonLinkInt } from "../Button"

const ContentTile = ({
  title = "Workshop Name",
  date = "No date",
  startTime = "No time set",
  endTime = null,
  text = "",
  textColor = "text-white",
  color,
  slug = "#",
  isVirtualEvent = null,
  showDate = true,
  showTime = true,
  presenters = "",
}) => {
  const { timezone } = useContext(ConferenceContext)

  const data = useStaticQuery(graphql`
    query ContentTileQuery {
      defaultContentImage: file(relativePath: { eq: "lakePlaceholder.jpg" }) {
        childImageSharp {
          fluid(fit: COVER, maxWidth: 1000, grayscale: true) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const contentTileFluid = data.defaultContentImage.childImageSharp.fluid

  // Assign colors based on "color" prop

  let dateColor = "text-white-500"
  let themeColor = "66,153,225"

  if (color === "blue") {
    dateColor = "text-blue-200"
    themeColor = "66,153,225"
  } else if (color === "green") {
    dateColor = "text-green-300"
    themeColor = "var(--nofnec-mediumgreen)"
  } else if (color === "red") {
    dateColor = "text-red-200"
    themeColor = "252,129,129"
  } else if (color === "black") {
    dateColor = "text-gray-500"
    themeColor = "0,0,0"
  }

  const backgroundFluidImageStack = [
    contentTileFluid,
    `linear-gradient(rgba(${themeColor}, 0.8), rgba(${themeColor}, 0.8))`,
  ].reverse()

  //
  return (
    <BackgroundImage
      fluid={backgroundFluidImageStack}
      className={`flex justify-center w-full gridText  bg-cover max-w-6xl`}
    >
      <div className={`flex  items-center ${textColor} my-6 px-6`}>
        <div className="">
          {showDate && (
            <div className={`${dateColor} font-extrabold text-4xl`}>
              {date || null}
            </div>
          )}
          {/* First Line */}
          <div className="uppercase tracking-wide font-bold text-center  md:text-2xl">
            {title}
          </div>
          {/* Presenters */}
          {presenters && (
            <p className=" text-lg md:text-2xl text-center">
              <span className="italic">Presented by:</span>
              <br /> {presenters}
            </p>
          )}
          {showTime && startTime ? (
            <div>
              Time: {startTime}
              {endTime && <span> - {endTime}</span>}
            </div>
          ) : null}

          {/* Description */}
          <p className=" text-xl">{text}</p>
          <div className="grid row-gap-6 col-gap-10  justify-center  items-center mt-5">
            <ButtonLinkInt
              to={`/workshops/${slug}`}
              text="More info"
              className="bg-yellow-600 text-white hover:text-yellow-600 hover:bg-white pt-3 pb-2 px-5 text-xl text-center"
            />

            {isVirtualEvent && (
              <div className="self-end font-bold text-center items-center">
                <span>✨</span>&nbsp;Virtual Event!&nbsp;<span>✨</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default ContentTile
