import React from "react"

import ContentTile from "./contentTile"
import ImageTile from "./imageTile"

const WorkshopContainer = ({ children }) => {
  return <div className="grid  justify-center md:flex ">{children}</div>
}

export const WorkshopTile1 = ({
  title,
  description,
  color,
  date,
  slug,
  startTime,
  endTime = null,
  eventLink = null,
  isVirtualEvent = null,
}) => {
  return (
    <WorkshopContainer>
      {/* <ImageTile /> */}
      <ContentTile
        title={title}
        text={description}
        color={color}
        date={date}
        startTime={startTime}
        endTime={endTime}
        slug={slug}
        eventLink={eventLink}
        isVirtualEvent={isVirtualEvent}
      />
    </WorkshopContainer>
  )
}

export const WorkshopTile2 = ({
  color,
  title,
  description,
  date,
  slug,
  startTime,
  endTime = null,
  eventLink = null,
  isVirtualEvent = null,
}) => {
  return (
    <WorkshopContainer>
      <ContentTile
        title={title}
        text={description}
        color={color}
        date={date}
        startTime={startTime}
        endTime={endTime}
        slug={slug}
        eventLink={eventLink}
        isVirtualEvent={isVirtualEvent}
      />
      {/* <ImageTile /> */}
    </WorkshopContainer>
  )
}
