import React from "react"
import RenderWorkshops from "./renderWorkshops"

export const DayTitle = ({ children }) => (
  <h1 className="text-center text-5xl">{children}</h1>
)

// List all of the workshops by date,  only if they have a date assigned to them
// takes in all workshops and filters for presence of date
export const ListWorkshopsByDate = ({ workshopDays, workshops }) => {
  return workshopDays.map(day => {
    // filter out workshops that don't have a date assigned to them
    const daysWorkshopsWithDate = workshops.filter(workshop => {
      /* ONLY render a workshop if it has a date assigned to it */

      if (workshop.node.conferenceDate) {
        // Date of workshop equals the day of the conference we want to target
        return workshop.node.conferenceDate.date === day.node.date
      }
      return null
    })

    /* Render the workshops for each day, only if there are 1 or more planned */

    return (
      <>
        {daysWorkshopsWithDate.length > 0 ? (
          <>
            <DayTitle>
              {day.node.title} / {day.node.date}
            </DayTitle>
            <RenderWorkshops workshops={daysWorkshopsWithDate} />
          </>
        ) : null}
      </>
    )
  })
}
