import React, { useState } from "react"
import { graphql, Link } from "gatsby"

import RenderWorkshops from "../components/workshops/renderWorkshops"
// import Filter from "../components/Filter"
import SEO from "../components/SEO"
import {
  DayTitle,
  ListWorkshopsByDate,
} from "../components/workshops/WorkshopHelpers"
import Layout from "../components/layout"
import { PageTitle } from "../components/SiteComponents"

const Workshops = ({ data }) => {
  data.allSanityWorkshop.edges.length > 0
    ? console.log("YES Workshops data", data)
    : console.log("No workshop data available")

  // array with all workshops listed
  const workshopData = data.allSanityWorkshop.edges

  // array for all of the workshop days
  const workshopDaysArray = data.allSanityDay.edges

  // -------- For FILTER ---------
  // set state for workshops to display, based on filter
  const [workshopsDisplayed, setWorkshopsDisplayed] = useState(workshopData)

  // array of kewords to filter for, with values

  // For dropdown menu filter
  const keywords = workshopDaysArray.map(day => ({
    display: day.node.title,
    value: day.node.date,
  }))

  let count = 0

  // Conditionally render a placeholder message if no workshops are ACTIVE/Un-archived in the CMS
  if (workshopData.length === 0) {
    return (
      <Layout>
        <SEO title="Workshops" />
        <div className="flex flex-col my-16 ">
          <PageTitle className=" text-nofnecGreen mt-20 md:mt-10">
            Workshops
          </PageTitle>
          <div className="self-center text-2xl text-center w-11/12 md:max-w-6xl">
            <p className="my-6">
              The planning committee is working hard at booking workshops.
              Please check this page and{" "}
              <a
                href="https://www.facebook.com/NOFNEC/"
                target="_blank"
                rel="noopener noreferrer"
                className="underline hover:text-nofnecGreen"
              >
                the NOFNEC Facebook page
              </a>{" "}
              regularly for updates.
            </p>
            <p>
              Thank you for your interest in the Northern Ontario First Nations
              Environment Conference!
            </p>
            <p>
              In the meantime, please check out out our{" "}
              <Link to="/past-events" className="underline">
                Past Events Page
              </Link>
              .
            </p>
          </div>
        </div>
      </Layout>
    )
    // Otherwise, render the days and their workshops
  } else {
    //filter for workshops that don't have any date assigned
    const workshopsNoDate = workshopsDisplayed.filter(workshop => {
      return !workshop.node.startTime
    })
    const workshopsWithDate = workshopsDisplayed.filter(
      workshop => workshop.node.startTime
    )

    return (
      <Layout>
        <SEO title="Workshops" />
        <div className="grid row-gap-3 mb-5 mt-32 md:mt-20">
          {/* Filter */}
          {/* <Filter
            keywords={keywords}
            changeState={setWorkshopsDisplayed}
            itemList={workshopData}
          /> */}
          <PageTitle className=" text-nofnecGreen mb-10">Workshops</PageTitle>

          {/* List of all workshops with a start time */}
          <DayTitle>Scheduled workshops</DayTitle>
          <RenderWorkshops workshops={workshopsWithDate} />

          {/* Render a kist for each date, with workshops on that date */}
          {/* commented out for now bc I am changing the ways dates work */}
          {/* <ListWorkshopsByDate
            workshopDays={workshopDaysArray}
            workshops={workshopData}
          /> */}

          <DayTitle>Workshops not yet scheduled</DayTitle>
          <RenderWorkshops workshops={workshopsNoDate} />
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query WorkshopsQuery {
    allSanityWorkshop(
      filter: { isActive: { eq: true } }
      sort: { order: ASC, fields: startTime }
    ) {
      edges {
        node {
          title
          summary
          slug {
            current
          }
          conferenceDate {
            date(formatString: "MMM DD")
          }
          startTime
          endTime
          workshopThemes {
            title
          }
          eventLink
          isVirtualEvent
          isActive
        }
      }
    }
    allSanityDay(sort: { fields: date }) {
      edges {
        node {
          id
          title
          date(formatString: "MMM DD")
        }
      }
    }
  }
`

export default Workshops
